import request from "@/utils/request";
// 活动新增
export function addActivityInfo(data) {
  return request("post", "/apm/platform/recharge/create", data);
}

// 活动列表
export function getActivityTableList(data) {
  return request("post", "/apm/platform/recharge/page", data);
}

// 查询优惠活动
export function getActivityInfo(data) {
  return request("post", "/apm/platform/recharge/findById", data);
}

// 编辑优惠活动
export function editActivityInfo(data) {
  return request("post", "/apm/platform/recharge/update", data);
}
// 删除优惠活动
export function delActivityInfo(data) {
  return request("post", "/apm/platform/recharge/del", data);
}
// 启动优惠活动
export function onActivityInfo(data) {
  return request("post", "/apm/platform/recharge/activityStart", data);
}
// 下架优惠活动
export function offActivityInfo(data) {
  return request("post", "/apm/platform/recharge/offShelf", data);
}
// 活动充值订单查询
export function orderList(data) {
  return request("post", "/apm/platform/recharge/order/page", data);
}
