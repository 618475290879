<!--平台充值活动列表-->
<template>
    <div class="activityList">
        <el-row :gutter="10" style="margin-bottom: 20px;">
            <el-col :span="4">
                <el-input v-model="tableParams.kw" placeholder="输入活动名称搜索" @input="kwChange" class="mr20" />
            </el-col>
            <el-col :span="4">
                <el-select v-model="tableParams.activityStatus" placeholder="请选择活动状态" @change="reload" clearable
                    class="mr20">
                    <el-option v-for="item in ObjToOpt(activityStatus)" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="4">
                <el-select v-model="tableParams.validityStatus" placeholder="请选择有效期状态" @change="reload" clearable
                    class="mr20">
                    <el-option v-for="item in ObjToOpt(activityDateStatus)" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="8">
                <SelecteMS @reload="update"></SelecteMS>
            </el-col>
            <el-col :span="4" style="text-align: end;">
                <el-button type="primary" @click="onAdd(1)">新增活动</el-button>
            </el-col>

        </el-row>

        <!-- 表格 -->
        <el-table :data="tableData" border stripe :header-cell-style="{
            background: '#FAFAFA',
            color: '#000000',
        }">
            <el-table-column label="活动名称" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>
                        <span>{{ scope.row.activityName }}</span></span>
                </template>
            </el-table-column>
            <el-table-column label="充值金额" align="center">
                <template slot-scope="scope">
                    <span>￥{{ scope.row.rechargeAmount }}</span>
                </template>
            </el-table-column>
            <el-table-column label="活动适用门店" min-width="120">
                <template slot-scope="scope">
                    <span>{{ scope.row.shopLimit || "-" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="活动有效期" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.activityStartTime ? scope.row.activityStartTime + "-" +
                        scope.row.activityEndTime : '长期有效' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="可充值次数" align="center" prop="canRechargeTimes">
            </el-table-column>
            <el-table-column label="活动须知" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-html="scope.row.activityNotice"></div>
                </template>
            </el-table-column>
            <el-table-column label="活动状态" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.activityStatusName }}</span>
                </template>
            </el-table-column>
            <el-table-column label="有效期状态" align="center">
                <template slot-scope="scope">
                    {{ scope.row.validityStatusName || "-" }}
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" min-width="100">
                <template slot-scope="scope">
                    <el-button type="text" size="medium" @click.stop="onAdd(2, scope.row)">查看</el-button>
                    <el-button v-if="scope.row.activityStatus != 1 && scope.row.validityStatus != 2" type="text"
                        size="medium" @click.stop="onAdd(3, scope.row)">编辑</el-button>
                    <el-button type="text" size="medium" @click="operate(scope.row, 1)"
                        v-if="scope.row.activityStatus != 1 && scope.row.validityStatus != 2">启动</el-button>
                    <el-button type="text" size="medium" @click="goConfirmDelete(scope.row)"
                        v-if="scope.row.activityStatus != 1">删除</el-button>
                    <el-button type="text" size="medium" @click="operate(scope.row, 2)"
                        v-if="scope.row.activityStatus == 1">下架</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </div>
        <!-- 编辑弹框 -->
        <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false"
            @close="closeEditDialog('form-ref')" append-to-body width="80%">
            <!-- 表单提交 -->
            <el-form :model="activityInfo" :rules="rules" ref="form-ref" label-width="200px"
                :disabled="dialogType == 2">
                <section class="form-main">
                    <div class="firstTitle">活动规则</div>
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-form-item label="活动名称" prop="activityName">
                                <el-input v-model.trim="activityInfo.activityName" autocomplete="off" maxlength="10"
                                    placeholder="请填写活动名称" clearable></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="充值金额" prop="rechargeAmount">
                                <el-input v-model="activityInfo.rechargeAmount" type="number" :step="1"
                                    placeholder="请输入充值金额" @keydown.native="(e) =>
                                    (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
                                        ? false
                                        : e.returnValue)
                                        " @input="numberInput"></el-input>

                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-form-item label="适用类型">
                                <el-radio-group v-model="activityInfo.suitShopFlag">
                                    <el-radio :label="0">所有门店</el-radio>
                                    <el-radio :label="1">部分门店</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="活动有效期">
                                <el-date-picker v-model="dateToDate" type="daterange" start-placeholder="开始日期"
                                    end-placeholder="结束日期" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                            <div style="color: red;font-size: 12px;margin-bottom: 10px;margin-left: 200px">默认不填表示长期有效
                            </div>
                        </el-col>
                    </el-row>
                    <el-row v-if="activityInfo.suitShopFlag == 1" :gutter="10">
                        <el-col>
                            <el-form-item label="适用门店" prop="shopIdSet">
                                <el-select v-model="activityInfo.shopIdSet" multiple collapse-tags placeholder="搜索选择门店"
                                    clearable filterable remote reserve-keyword :remote-method="shopRemoteMethod">
                                    <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName"
                                        :value="item.shopId">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-form-item label="可充值次数" prop="canRechargeTimes">
                                <el-input v-model="activityInfo.canRechargeTimes" type="number" :step="1"
                                    placeholder="请输入可充值次数" @keydown.native="(e) =>
                                    (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
                                        ? false
                                        : e.returnValue)
                                        "></el-input>

                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="角标文案" prop="signageText">
                                <el-input v-model.trim="activityInfo.signageText" autocomplete="off" type="text"
                                    maxlength="8" placeholder="请填写角标文案" clearable></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <!-- 富文本 -->
                        <el-col :span="24">
                            <el-form-item label="活动须知" prop="activityNotice" class="long-width" v-if="dialogType != 2">
                                <quill-editor v-model="activityInfo.activityNotice" ref="myQuillEditor"
                                    :options="editorOption" @change="onEditorChange1($event)">
                                </quill-editor>
                            </el-form-item>
                            <el-form-item label="活动须知" prop="activityNotice" class="long-width" v-else>
                                <div class="activityNotice" v-html="activityInfo.activityNotice"></div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="line"></div>
                    <div class="firstTitle">活动奖励（请至少配置一项）</div>
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-form-item label="赠送积分" prop="givePoints">
                                <el-input v-model="activityInfo.givePoints" type="number" :step="1"
                                    placeholder="请输入赠送积分" @keydown.native="(e) =>
                                    (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
                                        ? false
                                        : e.returnValue)
                                        " @input="numberInput"></el-input>

                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="赠送卡券" prop="giveCard">
                                <el-select v-model="activityInfo.giveCard" placeholder="请选择赠送卡券" multiple>
                                    <el-option v-for="item in cardList" :key="item.cardId" :label="item.cardName"
                                        :value="item.cardId">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="展示配置" prop="activityShowConfigDTOList" v-if="dialogType != 2">
                        <el-button type="primary" plain @click="addConfig">添加配置</el-button>
                    </el-form-item>
                    <div>
                        <div v-if="dialogType == 2" class="label">展示配置</div>
                        <div style="padding-left: 200px;display: flex;flex-wrap: wrap;">
                            <div class="config_item" v-for="(item, index) in activityInfo.activityShowConfigDTOList"
                                :key="index">

                                <img :src="item.welfareIconImg" alt="" srcset=""
                                    style="width:40px;height:40px;margin-right: 20px">
                                <p style="margin-right: 20px;">{{ item.welfareDescription }}</p>
                                <template v-if="dialogType != 2">
                                    <i class="el-icon-edit" style="margin-right: 20px;"
                                        @click="editConfig(item, index)"></i>
                                    <i class="el-icon-delete" @click="delConfig(index)"></i>
                                </template>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="form-footer" v-if="dialogType != 2">
                    <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
                </section>
            </el-form>
        </el-dialog>
        <el-dialog :title="configTitle" :visible.sync="showConfigEdit" :close-on-click-modal="false" append-to-body
            width="40%" center @close="closeConfigDialog('BenifitForm')">
            <el-form :model="configInfo" :rules="rules" ref="BenifitForm" label-width="120px">
                <!-- 商品缩略图 -->
                <el-form-item label="福利图标" prop="welfareIcon">
                    <div class="upload-btn" @click="onUpload(1, 1)">
                        <img v-if="configInfo.welfareIcon" :src="configInfo.welfareIconImg" class="upload-img">
                        <i v-else class="el-icon-plus uploader-icon"></i>

                    </div>
                </el-form-item>
                <div style="color: red;font-size: 12px;margin: 10px 0;margin-left: 120px">建议上传30*30的jpg/png图片</div>
                <el-form-item label="福利说明" prop="welfareDescription">
                    <el-input type="textarea" v-model="configInfo.welfareDescription" maxlength="10"
                        placeholder="请输入福利说明" show-word-limit></el-input>
                </el-form-item>
                <div style="text-align: center;width: 100%;">
                    <el-button type="primary" @click="saveConfig('BenifitForm')">保存</el-button>
                </div>

            </el-form>
        </el-dialog>
        <!-- 图片上传组件 -->
        <UpLoad @fileIds="getFileIds" :maxlimit="maxlimit" ref="up" :fileType="fileType"></UpLoad>
    </div>
</template>
<script>
import { ObjToOpt } from "@/utils/utils";
import { activityStatus, activityDateStatus } from "@/db/objs";
import { getTableList, goDeleteCard } from "@/api/card/card";
import {
    getShopList,
} from "@/api/commercial/shop";
import { addActivityInfo, getActivityTableList, getActivityInfo, editActivityInfo, delActivityInfo, onActivityInfo, offActivityInfo } from "@/api/activity/activity";
import { rules } from "@/db/rules";

import SelecteMS from '@/views/vip/components/selecteMS'
import UpLoad from "@/components/upload";
import {
    getFileList
} from "@/api/common";

import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import * as Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);
export default {
    components: {
        SelecteMS, UpLoad, quillEditor
    },
    data() {
        return {
            rules,
            ObjToOpt,
            activityStatus,
            activityDateStatus,
            currentPage: 1, // 当前页
            total: 0, // 总条数
            tableData: [],
            shopList: [],
            tableParams: {
                kw: '',
                activityStatus: '',
                validityStatus: '',
                merchantId: '',
                shopId: '',
                page: 1,
                pageSize: 10,
            },
            shopParams: {
                page: 1,
                size: 0,
                shopStatus: 1,
                keyWord: "",//查询关键字
            },
            dateToDate: [],
            editTitle: '查看活动',
            configTitle: '添加配置',
            editShow: false,
            showConfigEdit: false,
            dialogType: 1,//1新增，2查看，3编辑
            activityInfo: {
                suitShopFlag: 0,
                canRechargeTimes: 1,
                activityShowConfigDTOList: []
            },//活动信息
            configInfo: {

            },
            fileList: [],//图片列表
            cardList: [],//卡券列表
            fileType: 1,
            upType: "",
            editorOption: {
                modules: {
                    toolbar: {
                        container: [
                            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                            ['blockquote', 'code-block'], // 引用  代码块
                            [{ header: 1 }, { header: 2 }], // 1、2 级标题
                            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                            [{ indent: '-1' }, { indent: '+1' }], // 缩进
                            [{ direction: 'rtl' }], // 文本方向
                            [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
                            [{ header: [1, 2, 3, 4, 5] }], // 标题
                            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                            [{ align: [] }], // 对齐方式
                            ['clean'], // 清除文本格式

                        ],

                    },
                    // 调整图片大小
                    imageResize: {
                        displayStyles: {
                            backgroundColor: 'black',
                            border: 'none',
                            color: 'white'
                        },
                        modules: ['Resize', 'DisplaySize', 'Toolbar']
                    }
                },
                placeholder: '请填写正文'
            },
        };
    },
    computed: {
        upBtnShow() {
            if (this.fileList && this.fileList.length > 5) {
                return false
            } else if (!this.fileList || this.fileList.length < 5) {
                return true
            }
        },
        maxlimit() {
            if (this.fileList && this.fileList.length > 0) {
                return 5 - this.fileList.length
            } else {
                return 5
            }
        }
    },
    // 监听属性  
    watch: {
        fileList(val) {

            let arr = [];
            if (val && val.length > 0) {
                for (let i of val) {
                    arr = [...arr, i.attId]
                }
            }
            this.activityInfo.imageIds = arr.toString()

        },
    },
    mounted() {
        this._getShopList()
        this.getCardList(); // 【请求】卡券配置项列表
    },
    methods: {
        reset() {
            this.dateToDate = []
            this.activityInfo = {
                suitShopFlag: 0,
                canRechargeTimes: 1,
                activityShowConfigDTOList: []
            }
        },
        numberInput(value) {
            if (value.length > 8) { this.activityInfo.rechargeAmount = value.slice(0, 8) }
        },
        // 选择已经启用的店铺作为商品可领取店铺
        _getShopList() {

            getShopList(this.shopParams).then(res => {
                if (res.isSuccess == 'yes') {
                    this.shopList = res.data.list
                }
            })
        },
        // 门店搜索
        shopRemoteMethod(query) {
            this.shopParams.keyWord = query;
            if (query !== "") {
                this.loading = true;
                this._getShopList();
            } else {
                this.shopList = [];
            }
        },
        // 获取活动列表
        getTableList() {
            getActivityTableList(this.tableParams).then((res) => {
                if (res.isSuccess == "yes") {
                    this.tableData = res.data.list;
                    this.total = res.total;
                }
            });
        },
        // 查询活动详情
        getActivityInfo(id) {
            getActivityInfo({ activityId: id }).then(res => {
                this.activityInfo = res.data
                if(this.activityInfo.giveCard!=''){
                    this.activityInfo.giveCard=this.activityInfo.giveCard.split(',').map(item=>Number(item))
                }else{
                    this.activityInfo.giveCard=[]
                }
                console.log(this.activityInfo.giveCard)
                if (this.activityInfo.activityStartTime) {
                    this.dateToDate = [this.activityInfo.activityStartTime, this.activityInfo.activityEndTime]
                } else {
                    this.dateToDate = []
                }
            })
        },
        // 删除卡券
        delActivityInfo(activityId) {
            delActivityInfo({ activityId }).then((res) => {
                if (res.isSuccess == "yes") {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                    });
                    this.getTableList();
                }
            });
        },
        // 二次确认删除卡券
        goConfirmDelete(row) {
            let activityId = row.activityId;
            let tips = '是否确认删除该活动?'
            this.$confirm(tips, "删除活动", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 接口
                    this.delActivityInfo(activityId);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },

        // 【请求】卡券选项数据
        getCardList() {
            let data = {
                cardType: "",
                page: 1,
                size: 0
            };
            getTableList(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.cardList = res.data.list;

                }
            });
        },
     
        // 【监听】编辑
        onAdd(type, row) {
            this.editShow = true
            this.dialogType = type
            //  1新增 2编辑
            if (type == 1) {
                this.editTitle = '新增活动'
            } else if (type == 2) {
                this.getActivityInfo(row.activityId)
                this.editTitle = '查看活动'

            } else {
                this.getActivityInfo(row.activityId)
                this.editTitle = '编辑活动'

            }
        },
        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getTableList();
        },
        // 【监听】表格模糊查询
        kwChange: _.debounce(function () {
            this.reload();
        }, 500),
        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getTableList();
        },

        // 【监听】表格条数点击
        onSizeChange(size) {
            this.tableParams.size = size;
            this.reload();
        },
        update(e) {
            this.tableParams.merchantId = e.merchantId;
            this.tableParams.shopId = e.shopId;
            this.reload()
        },
        // 【监听】文件上传
        onUpload(fileType, upType) {
            this.fileType = fileType;
            this.upType = upType;
            this.$refs.up.onUpload();
        },

        // 【监听】获取上传文件ID集合
        getFileIds(fileIds) {
            let data = {
                fileIds
            }
            getFileList(data).then(res => {
                if (res.isSuccess == "yes") {
                    // 缩略图
                    if (this.upType == 1) {
                        this.configInfo.welfareIcon = fileIds;
                        this.configInfo.welfareIconImg = res.data[0].url;
                    }
                    this.$forceUpdate();
                }
            })
        },

        cutUrl(url) {
            let index = url.indexOf('?');
            return url.slice(0, index)
        },

        onImgDel(item) {
            this.$confirm('你确定要删除吗?', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.fileList.splice(this.fileList.indexOf(item), 1);
            });
        },
        // 【监听】富文本内容改变事件
        onEditorChange1({ quill, html, text }) {
            this.activityInfo.activityNotice = html
        },
        addConfig() {
            this.showConfigEdit = true
        },
        editConfig(item, index) {
            this.configInfo = JSON.parse(JSON.stringify(item))
            this.configInfo.index = index
            this.showConfigEdit = true
        },
        delConfig(index) {
            this.activityInfo.activityShowConfigDTOList.splice(index, 1)
        },
        operate(row, type) {
            this.$confirm(`是否确认${type == 1 ? '启动' : '下架'}该活动?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                if (type == 1) {
                    onActivityInfo({ activityId: row.activityId }).then(res => {
                        if (res.isSuccess == 'yes') {
                            this.$message.success("启动成功")
                            this.reload()
                        }
                    })

                } else {
                    offActivityInfo({ activityId: row.activityId }).then(res => {
                        if (res.isSuccess == 'yes') {
                            this.$message.success("下架成功")
                            this.reload()
                        }
                    })
                }
            });
        },


        // 点击添加福利配置
        saveConfig(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.configInfo.index != null) {
                        this.activityInfo.activityShowConfigDTOList[this.configInfo.index] = this.configInfo
                    } else {
                        this.activityInfo.activityShowConfigDTOList.push(this.configInfo)
                    }

                    this.configInfo = {}
                    this.$refs[formName].clearValidate();
                    this.showConfigEdit = false;

                } else {
                    return false;
                }
            })
        },
        closeConfigDialog(formName) {
            this.configInfo = {}
            this.$refs[formName].clearValidate();
        },
        closeEditDialog(formName) {
            this.reset()
            this.$refs[formName].clearValidate();
        },
        onSubmit: _.debounce(function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.activityInfo.giveCard == '' && (this.activityInfo.givePoints ==''||this.activityInfo.givePoints ==null)) {
                        this.$message.error("活动奖励至少选择一项");
                        return false
                    }
                    this.activityInfo.giveCard = this.activityInfo.giveCard.join(",")
                    if (this.dateToDate && this.dateToDate.length > 0) {
                        this.activityInfo.activityStartTime = this.dateToDate[0];
                        this.activityInfo.activityEndTime = this.dateToDate[1];
                    } else {
                        this.activityInfo.activityStartTime = null;
                        this.activityInfo.activityEndTime = null;
                    }
                    if (this.activityInfo.activityId) {
                        editActivityInfo(this.activityInfo).then(res => {
                            if (res.isSuccess == "yes") {
                                this.$message.success("修改成功");
                                this.editShow = false;
                                this.getTableList();
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                    } else {
                        addActivityInfo(this.activityInfo).then(res => {
                            if (res.isSuccess == "yes") {
                                this.$message.success("添加成功");
                                this.editShow = false;
                                this.getTableList();
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                    }
                } else {

                    return false;
                }
            });
        }, 800)


    },
};
</script>
<style lang="scss" scoped>
.config_item {
    width: 33%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.label {
    font-size: 14px;
    color: #606266;
    margin-right: 12px;
    width: 188px;
    text-align: end;
    padding-right: 12px;
}

.activityNotice {
    width: 100%;
    padding: 10px;
    border: 1px solid #eee;
}

.activityList {
    padding: 0.15rem;
    box-sizing: border-box;
}

.table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.15rem;
}

.search-L {
    display: flex;
}

.form-footer {
    text-align: end;
}

.firstTitle {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin: 10px 0;
}

.table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
}

.upload-btn {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}

.upload-btn:hover {
    border-color: #409EFF;
}

.uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
}

.upload-img {
    display: inline-block;
    border: 1px dashed #d9d9d9;
    margin: 0 .1rem .1rem 0;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    position: relative;

    >img {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
}

.tip {
    color: red;
    display: flex;
}

.imgs {
    display: flex;
    flex-wrap: wrap;
}

.img-del {
    width: 1rem;
    height: 1rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    visibility: hidden;

    >.el-icon-delete {
        font-size: 28px;
        color: #fff;
    }
}

.upload-img:hover .img-del {
    visibility: visible;
}
</style>
<style>
.long-width .el-form-item__content {
    width: 80%;
}

.ql-container {
    min-height: 3rem;
}
</style>